/**
 * A temporary solution to not being able to upgrade angular. If the version of angular is above 8 then please delete this file!
 */
// @ts-ignore
if (Promise && !Promise.allSettled) {
  // @ts-ignore
  Promise.allSettled = promises => {
    return Promise.all(
      (promises instanceof Array ? promises : [promises]).map(promise => {
        if (!promise) {
          return { state: 'fulfilled', value: null };
        }
        return promise
          .then((value: any) => {
            return { state: 'fulfilled', value: value };
          })
          .catch((reason: any) => {
            return { state: 'rejected', reason: reason };
          });
      }),
    );
  };
}
