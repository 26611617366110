import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {environment} from './environments/environment';
import {extendConfiguration} from './config';

import {init} from '@sentry/browser';
import './app/shims/all-settled';

extendConfiguration().then(async () => {
  if (environment.production) {
    enableProdMode();
  }

  if (environment.sentry) {
    init({
      dsn: environment.sentry,
      environment: environment.orderApiUrl
        .split('.')
        .splice(2, 3)
        .join('.'),
      release: environment.version,
    });
  }

  const module = await import('./app/app.module');
  platformBrowserDynamic().bootstrapModule(module.AppModule)
    .catch(err => console.error(err));
});
